import { useCallback, useEffect, useState } from "react";
import ActionsContainer from "./components/ActionsContainer";
import MapContainer from "./components/MapContainer";
import judete from "./utils/judete";

function App() {
  const [countySelected, setCountySelected] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
      window.onN8NResult = (res) => {
          setData((prevState) => [...prevState, res]);
      }
  }, [setData]);
  // const handleCountiesSelect = useCallback((item) => {
  //   setCountySelected((prev) => [...prev, item]);
  // }, []);

  useEffect(() => {
    console.log("ccccc", countySelected);
  }, [countySelected]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const url = new URL(window.location.href);
   const searchParams = url.searchParams;

   if (searchParams.get("date")) {
       return searchParams.get("date");
   }
      //return "2023-01-01";
    return formattedDate;
  };

  const handleSentPrompt = useCallback(
    async (prompt, keywords) => {
      setLoadingData(true);

      let requestData = {
        prompt: prompt,
        date: getCurrentDate(),
        keywords: keywords || "",
      };


      let mainJudete = countySelected.length > 0 ? countySelected : judete;

      let generateFetches = mainJudete.map((judet) =>
        fetch(
          `https://n8n.centy.so/webhook/1163d0a3-f412-4b0a-a116-06d419aae9af?` +
            new URLSearchParams({
              ...requestData,
              judet: judet,
                channel: window.channelURL
            }),
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
            },
          }
        ).catch((e) => console.log(e))
      );

      if (generateFetches.length !== mainJudete.length) {
        console.log("not all fetches");
        return;
      }

      const res = await Promise.all(generateFetches);
      const jsonFetches = await Promise.all(
        res.map((res) => res?.json() || {})
      );

      if (jsonFetches) {
        console.log("jsonFetches", jsonFetches);
        setData(jsonFetches);
        setLoadingData(false);
      }
    },
    [countySelected]
  );

  return (
    <div className="bg-[#F2F6FA] min-h-screen w-full h-full flex items-center max-w-[1800px] mx-auto">
      <MapContainer
        setCountySelected={setCountySelected}
        countySelected={countySelected}
        data={data}
      />

      <ActionsContainer
        handleSentPrompt={handleSentPrompt}
        loadingData={loadingData}
      />
    </div>
  );
}

export default App;
