import { MultiSelect, Tooltip } from "@mantine/core";
import React, { useMemo, useState } from "react";
import RefreshIcon from "../icons/RefreshIcon";
import SearchIcon from "../icons/SearchIcon";
import judete from "../utils/judete";

const MapActions = ({ countySelected, setCountySelected }) => {
  const [searchValue, onSearchChange] = useState("");

  const formattedJudete = judete.map((name) => ({
    label: name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    value: name,
  }));

  const label_title = useMemo(() => {
    if (!countySelected.length) return "Explorează toate județele";
    if (countySelected.length === 1)
      return `Exploreaza doar pentru ${countySelected[0]}`;
    if (countySelected.length > 1)
      return `Exploreaza pentru judetele selectate`;
  }, [countySelected]);

  const label_description = useMemo(() => {
    if (!countySelected.length)
      return "Interogarea ta este rulată pe setul de date specific fiecărui județ";
    if (countySelected.length === 1)
      return `Orice intrebare va fi doar pentru judetul selectat`;
    if (countySelected.length > 1)
      return `Orice intrebare va fi doar pentru judetele selectate`;
  }, [countySelected]);

  return (
    <div className="absolute top-0 left-0 w-full px-8 backdrop-blur-md ">
      <div className="py-6 w-full border-b border-[#D6DDE5] flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {!!countySelected.length && (
            <Tooltip
              label="Reîmprospătează"
              styles={{
                tooltip: {
                  background: "#ffffff",
                  border: "1px solid #60a5fa",
                  color: "#60a5fa",
                },
              }}
            >
              <button
                onClick={() => setCountySelected([])}
                className="rounded-full w-[50px] h-[50px] bg-white border border-blue-400 flex items-center justify-center"
              >
                <RefreshIcon width={25} height={25} fill={"#60a5fa"} />
              </button>
            </Tooltip>
          )}
          <div className="flex flex-col flex-1">
            <span className="text-2xl font-medium pb-2">{label_title}</span>
            <span className="text-[#8C9CAF] text-sm">{label_description}</span>
          </div>
        </div>
        <div className="relative max-w-[300px] w-full ">
          <MultiSelect
            styles={{
              input: {
                background: "transparent",
                fontSize: "16px",
                border: "1px solid #D6DDE5",
                borderRadius: "12px",
                color: "#8C9CAF",

                "::placeholder": {
                  color: "#8C9CAF",
                },
              },
              value: {
                background: "white",
                color: "#8C9CAF",
                border: "1px solid #8C9CAF",
              },
            }}
            rightSection={<SearchIcon fill="#93A2B3" width={18} height={18} />}
            data={formattedJudete}
            placeholder="Alege un judet..."
            searchable
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            value={countySelected}
            onChange={setCountySelected}
            nothingFound="Nu s-a gasit nici un judet"
          />
        </div>
      </div>
    </div>
  );
};

export default MapActions;
