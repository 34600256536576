const judete = [
  "Timiș",
  "Bacău",
  "Giurgiu",
  "Galați",
  "Maramureș",
  "Olt",
  "Arad",
  "Harghita",
  "Botoșani",
  "Vrancea",
  "Prahova",
  "Bistrița-Năsăud",
  "Alba",
  "Vâlcea",
  "Ilfov",
  "Sălaj",
  "Tulcea",
  "Bihor",
  "Brașov",
  "Suceava",
  "Ialomița",
  "Buzău",
  "Brăila",
  "Vaslui",
  "Iași",
  "Mureș",
  "Dolj",
  "Constanța",
  "Gorj",
  "Argeș",
  "Mehedinți",
  "Neamț",
  "Caraș-Severin",
  "Călărași",
  "Teleorman",
  "Satu Mare",
  "Dâmbovița",
  "Hunedoara",
  "Covasna",
  "Sibiu",
  "Cluj",
];
const judete2 = `"Alba"
"Arad"
"Arges"
"Bacau"
National
"Bihor"
"Bistrita-Nasaud"
"Botosani"
"Braila"
"Brasov"
"Buzau"
"Calarasi"
"Caras-Severin"
"Cluj"
"Constanta"
"Covasna"
"Dolj"
"Galati"
"Giurgiu"
"Gorj"
"Harghita"
"Hunedoara"
"Ialomita"
"Iasi"
"Maramures"
"Mehedinti"
"Moldova"
"Mures"
"National"
"Neamt"
"Olt"
"Prahova"
"Salaj"
"Satu Mare"
"Sibiu"
"Suceava"
"Teleorman"
"Timis"
"Tulcea"
"Valcea"
"vaslui"
"Vrancea"`.replaceAll('"', "").split("\n")
export default judete;
