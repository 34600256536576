import React from "react";
import MainMap from "./MainMap";
import MapActions from "./MapActions";

const MapContainer = ({ data, countySelected, setCountySelected }) => {
  return (
    <div className="bg-[#F2F6FA] px-5 w-full min-h-[inherit] h-[inherit] overflow-hidden relative">
      <MapActions
        countySelected={countySelected}
        setCountySelected={setCountySelected}
      />

      <MainMap
        setCountySelected={setCountySelected}
        countySelected={countySelected}
        data={data}
      />
    </div>
  );
};

export default MapContainer;
