import React, { useEffect, useState } from "react";
import SendIcon from "../icons/SendIcon";

const ActionsContainer = ({ handleSentPrompt, loadingData }) => {
  const [prompt, setPrompt] = useState("");
  const [keywords, setKeywords] = useState("");

  const handleOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!!prompt.length) {
        return handleSentPrompt(prompt, keywords);
      }
    }
  };

  useEffect(() => {
    console.log("LOADING", loadingData);
  }, [loadingData]);

  return (
    <div className="bg-white flex flex-col max-w-[500px] w-full h-full min-h-[100vh] items-center pt-10 shadow-xl px-6">
      <span className="font-sembiold text-3xl">Interogare LLM</span>

      <div className="w-full mt-5 flex flex-col h-full relative py-4 pl-4 border-gray-400 border rounded-md flex-grow-0">
        <textarea
          onChange={(e) => setPrompt(e.target.value)}
          value={prompt}
          onKeyDown={handleOnEnter}
          placeholder="ex: Articolul este favorabil"
          className="outline-none m-0 w-full h-full resize-none min-h-[24px] pr-10 "
        />
        <button
          onClick={() => handleSentPrompt(prompt, keywords)}
          disabled={!prompt.length}
          type="button"
          className="rounded-md cursor-pointer outline-none border-gray-400 p-1 border absolute right-3 bottom-[23px]"
        >
          <SendIcon width={15} height={15} fill={"#9ca3af"} />
        </button>
      </div>
      <div className="flex flex-col mt-3 w-full">
        <span>Filtre</span>
        <span className="text-xs text-gray-500 mb-2">
          e.g. breaking news,declaratie,decizie
        </span>
        <input
          className="outline-none border-gray-400 border rounded-md py-2 px-2"
          onChange={(e) => setKeywords(e.target.value)}
          value={keywords}
        />
        <span className="text-xs text-gray-500 mt-2">
          ~ 3.000.000 vectors
        </span>
      </div>

    </div>
  );
};

export default ActionsContainer;
